<template>
  <div style="width: 100%;">
    <v-row>
      <v-col cols="4">
        <v-container>
          <v-row>
            <v-col class='d-flex flex-row align-center'>
              <h1>Add New {{this.singular}}<span v-if="this.data.name">:</span> {{this.data.name}}</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="Name"
                  outlined
                  v-model="data.name"
              />
              <v-text-field
                  label="Description"
                  outlined
                  v-model="data.description"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="create" :disabled="!this.data.name">Create</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col>
        <v-container>
          <v-row>
            <v-col class="d-flex flex-row align-center">
              <h1>{{this.plural}}</h1>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                  :headers="headers"
                  :items="allData"
                  :items-per-page="1000000"
                  class="elevation-1"
                  @click:row="rowClick"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters, mapMutations} from "vuex";

  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Description', value: 'description' },
          { text: 'Orders', value: 'numOrders' },
        ],
        editMode: false,
        allData: [],
        data: {},
        singular: "Order Type",
        singularLower: "ordertype",
        plural: "Order Types",
        pluralLower: "ordertypes"
      }
    },
    async mounted(){
        await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint'])
    },
    methods: {
      ...mapMutations(["setSnack"]),
      rowClick(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      async getAllData(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}`)
          this.allData = res.data
        } catch (error) {
          console.error(error);
        } finally {
          this.loader = false;
        }
      },
      async create(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`, this.data)
          if(res.data.id){
            this.setSnack({snack: true, snackText: `${this.singular} Created 🎉`});
            this.data={};
            await this.getAllData();
          }
        } catch (error) {
          console.error(error)
        }
      },
    }
  }
</script>
